import React from "react";
import styled from "styled-components";
import selfPortrait from "../img/Self-Portrait.png";

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: grid;
	position: relative;
	overflow: hidden;
	z-index: 1;

	animation: animateBg 25s alternate infinite;
	background-image: linear-gradient(
		318deg,
		#57737a,
		#bed2d5,
		#bca446,
		#f7b681,
		#f5c8c8,
		#fdecdf,
		#bed2d5,
		#57737a
	);

	background-size: 1000% 1000%;
	@keyframes animateBg {
		0% {
			background-position: 5% 0%;
		}
		50% {
			background-position: 96% 100%;
		}
		100% {
			background-position: 5% 0%;
		}
	}
`;

const Wrapper = styled.div`
	background: none;

	display: flex;
	flex-direction: column;
	justify-items: center;

	align-items: center;
	align-content: center;
	grid-column: 1;
	grid-row: 1;
`;

const Title = styled.h1`
	font-size: 64px;
	font-family: "Holidream";
	color: #2f2f2f;
	font-weight: 400;

	@media screen and (max-width: 600px) {
		font-size: 12vw;
	}
`;

const DescContainer = styled.div`
	display: flex;
	backdrop-filter: blur(2px);
	justify-content: center;
	text-align: center;
	@media screen and (max-width: 600px) {
		font-size: 5vw;
		width: 85%;
		flex-wrap: wrap;
	}
`;

const Desc = styled.p`
	padding: 0 1vw 0 1vw;
	font-size: 1.5rem;
	font-weight: bold;
	color: #2f2f2f;
`;

const Bubbles = styled.div`
	display: grid;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
`;

const SelfPortrait = styled.img`
	width: 60vh;
	padding-top: 10vh;
	grid-column: 1;
	grid-row: 1;
	@media screen and (max-width: 600px) {
		max-width: 85vw;
		padding-top: 5vh;
	}
`;

const Intro = () => {
	return (
		<Container>
			<Wrapper>
				<Bubbles>
					<SelfPortrait src={selfPortrait} alt="self portrait" />
				</Bubbles>

				<Title>Hello, I'm Elena</Title>
				<DescContainer>
					<Desc>Frontend Engineer</Desc>
				</DescContainer>
				<DescContainer>
					<Desc>My Projects</Desc>
				</DescContainer>
			</Wrapper>
		</Container>
	);
};

export default Intro;
