import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Main from './pages/Main';
import Slider from './pages/Slider';
import QG from './pages/QG';
import Intro from './pages/Intro';
import Movie from './pages/Movie';
import Shopping from './pages/Shopping';
import ElenaFacts from './pages/ElenaFacts';
import AirbnbClone from './pages/AirbnbClone';
import SquareApiTest from './pages/SquareApiTest';
import ContactMe from './pages/ContactMe';

//routes
const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/'
					element={<Main />}>
					<Route
						index
						element={<Intro />}
					/>
					<Route
						path='quotegenerator'
						element={<QG />}
					/>
					{/* <Route
						path='login'
						element={<Login />}
					/> */}
					<Route
						path='slider'
						element={<Slider />}
					/>
					<Route
						path='shoppinglist'
						element={<Shopping />}
					/>
					<Route
						path='movielist'
						element={<Movie />}
					/>
					<Route
						path='elenafacts'
						element={<ElenaFacts />}
					/>
					{/* <Route
						path='airbnbclone'
						element={<AirbnbClone />}
					/> */}
					<Route
						path='squareapitest'
						element={<SquareApiTest />}
					/>
					<Route
						path='contactme'
						element={<ContactMe />}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
