import React from 'react';
import styled from 'styled-components';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import { NavLink } from 'react-router-dom';
//import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import GitHubIcon from '@mui/icons-material/GitHub';
import MovieIcon from '@mui/icons-material/Movie';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Brightness4RoundedIcon from '@mui/icons-material/Brightness4Rounded';
//import FileCopyIcon from '@mui/icons-material/FileCopy';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import EmailIcon from '@mui/icons-material/Email';

//rgb(87, 115, 122, 0.7)
const Main = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	width: 78px;
	height: 100vh;
	backdrop-filter: blur(2px);
	background-color: #0000007f;
	padding: 6px 14px;
	z-index: 99;
	transition: all 0.5s ease;
	overflow: hidden;

	&:hover,
	nav.main-menu.expanded {
		width: 250px;
		overflow: visible;
	}
`;

const MainTitleDiv = styled.div`
	display: flex;
	align-items: center;
`;

const MainIcon = styled.i`
	color: #fdecdf;
	min-width: 50px;
	font-size: 32px;
	text-align: center;
`;

const MainTitle = styled.h3`
	margin: 0;
	color: #fdecdf;
	text-align: center;
	margin-top: 1rem;
`;

const Title = styled.h3`
	font-family: 'Montserrat', sans-serif;
	margin-left: 4px;
	align-items: center;
`;

const SidebarMenu = styled.div`
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
`;

const SideButton = styled.div`
	position: relative;
	margin: 8px 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Icon = styled.i`
	color: #fdecdf;
	height: 3rem;
	min-width: 50px;
	font-size: 2rem;
	text-align: center;
	line-height: 3rem;
`;

const SideSpan = styled.span`
	display: flex;
	height: 100%;
	width: 100%;
	font-size: 16px;
	align-items: center;
	text-decoration: none;
	transition: all 0.4s ease;
	flex-direction: row;
`;

const AboutMe = styled.div`
	display: flex;
	flex-direction: column;
`;

const AboutRow = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

const SelfImage = styled.img`
	height: 50px;
	width: 50px;
	object-fit: cover;
`;

const ProjDiv = styled.div`
	display: flex;
	flex-direction: column;
`;

const Sidebar = () => {
	return (
		<Main className='main-menu'>
			<SidebarMenu
				id='primary-navigation'
				data-visible='false'>
				<ProjDiv>
					<MainTitleDiv>
						<MainIcon></MainIcon>
						<MainTitle>Projects</MainTitle>
					</MainTitleDiv>

					<NavLink
						to='/squareapitest'
						className='projLink'>
						<SideButton>
							<Icon>
								<CreditScoreIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Square Pay API</SideSpan>
						</SideButton>
					</NavLink>

					<NavLink
						to='/quotegenerator'
						className='projLink'>
						<SideButton>
							<Icon>
								<EcoOutlinedIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Quote Generator</SideSpan>
						</SideButton>
					</NavLink>

					{/* <NavLink
						to='/login'
						className='projLink'>
						<SideButton>
							<Icon>
								<LockOutlinedIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Login</SideSpan>
						</SideButton>
					</NavLink> */}

					<NavLink
						to='/slider'
						className='projLink'>
						<SideButton>
							<Icon>
								<BurstModeIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Photo Slider</SideSpan>
						</SideButton>
					</NavLink>

					<NavLink
						to='/movielist'
						className='projLink'>
						<SideButton>
							<Icon>
								<MovieIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Movie List</SideSpan>
						</SideButton>
					</NavLink>

					<NavLink
						to='/shoppinglist'
						className='projLink'>
						<SideButton>
							<Icon>
								<ShoppingCartIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Shopping List</SideSpan>
						</SideButton>
					</NavLink>

					<NavLink
						to='/elenafacts'
						className='projLink'>
						<SideButton>
							<Icon>
								<Brightness4RoundedIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Elena Facts</SideSpan>
						</SideButton>
					</NavLink>
					{/* <NavLink
						to='/airbnbclone'
						className='projLink'>
						<SideButton>
							<Icon>
								<FileCopyIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Airbnb Clone</SideSpan>
						</SideButton>
					</NavLink> */}
					<NavLink
						to='/contactme'
						className='projLink'>
						<SideButton>
							<Icon>
								<EmailIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Contact Me</SideSpan>
						</SideButton>
					</NavLink>
				</ProjDiv>

				<AboutMe>
					<a
						href='https://elenadiaz.space'
						target='_blank'
						rel='noreferrer'
						className='aboutLink'>
						<AboutRow>
							<SelfImage
								src='./photos/Self-Portrait.png'
								alt=''
							/>
							<Title>Elena Diaz</Title>
						</AboutRow>
					</a>
					<a
						href='https://www.linkedin.com/in/elenadiazse'
						target='_blank'
						rel='noreferrer'
						className='aboutLink'>
						<AboutRow>
							<Icon>
								<LinkedInIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>LinkedIn</SideSpan>
						</AboutRow>
					</a>
					{/* <a
						href='https://github.com/diazelena325'
						target='_blank'
						rel='noreferrer'
						className='aboutLink'>
						<AboutRow>
							<Icon>
								<GitHubIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Github</SideSpan>
						</AboutRow>
					</a>
					<a
						href='https://www.instagram.com/journey2japanwithhusky/'
						target='_blank'
						rel='noreferrer'
						className='aboutLink'>
						<AboutRow>
							<Icon>
								<InstagramIcon className='SocialIconSize' />
							</Icon>
							<SideSpan>Instagram</SideSpan>
						</AboutRow>
					</a> */}
				</AboutMe>
			</SidebarMenu>
		</Main>
	);
};

export default Sidebar;
