import React from "react";
import styled from "styled-components";
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;

	background-color: rgba(255, 255, 255, 0.021);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ThisFrame = styled.iframe`
	border: none;
	height: 100vh;
	width: 100vw;
`;

const Shopping = () => {
	return (
		<Container>
			<ThisFrame title="Shopping" src="/shopping_list/index.html"></ThisFrame>
		</Container>
	);
};

export default Shopping;
