import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;

	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ThisFrame = styled.iframe`
	border: none;
	height: 100vh;
	width: 100vw;
`;

const SquareApiTest = () => {
	return (
		<Container>
			<ThisFrame
				title='SquarePayAPI'
				src='/sqrcfe_prod/index.html'></ThisFrame>
		</Container>
	);
};

export default SquareApiTest;
