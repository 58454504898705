import React, { useState } from 'react';
import styled from 'styled-components';
import LoginForm from '../components/LoginForm';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #53565A;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    appearance: none;
    font-family: Montserrat,sans-serif;
    border: none;
    outline: none;
`;

const Welcome = styled.div`
     margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat,sans-serif;
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    padding: 15px 30px;
`;

const WelcomeButton = styled.button`
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #FFCE00 50%, #FFCE00 50%, #FE4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #666;
    font-weight: 700;
    cursor: pointer;
    appearance: none;
   
    
    outline: none;
    font-family: Montserrat,sans-serif;
    :hover{
background-position: 100% 0%;
color: white;
}

`;

const Welcomeh2 = styled.h2`
color: #888;
    font-style: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    font-family: Montserrat,sans-serif;
`;

const Wspan = styled.span`
color: #FE4880;
    font-weight: 700;
    font-family: Montserrat,sans-serif;
`;

const Login = () => {
    const adminUser = {
        email: "admin@admin.com",
        password: "admin123"
          }
        
        const [user, setUser] = useState({name: "", email: ""});
        const [error, setError] = useState("");
        
        
        const Login = details => {
          console.log(details);
        
          if(details.email === adminUser.email && details.password === adminUser.password){
            console.log("Logged in");
            setUser({
              name: details.name,
              email: details.email
            });
          } else{
            console.log("Details do not match!");
        setError("Details do not match!");
        
          }
        }
        
        const Logout = () => {
          console.log("Logout");
        
          setUser({name: "", email: ""});
        }
  return (
    <Container>
    {(user.email !== "") ? (
     <Welcome>
       <Welcomeh2> Welcome, <Wspan>{user.name}</Wspan></Welcomeh2>
       <WelcomeButton onClick={Logout} >Logout</WelcomeButton>
       </Welcome>
    ) : (
     <LoginForm Login={Login} error={error} />
    )}
   </Container>
  )
}

export default Login