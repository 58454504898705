import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../components/Sidebar";

const Container = styled.div`
	width: 100%;
	height: 100%;
`;
const ProjectDiv = styled.div`
	display: flex;
	height: 80%;
	overflow: hidden;
`;

const Main = () => {
	return (
		<Container>
			<Sidebar />
			<ProjectDiv>
				<Outlet />
			</ProjectDiv>
		</Container>
	);
};

export default Main;
