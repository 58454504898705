import React, { useState } from 'react'
import styled from 'styled-components';

const LogForm = styled.form`
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat',sans-serif;

    :after{
        
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 1;
    background-image: linear-gradient(to bottom right, #FFCE00, #FE4880);
}
`;

const FormInner = styled.div`
       position: relative;
    display: block;
    background-color: #fff;
    padding: 30px;
    z-index: 2;
  
`;

const Formh2 = styled.h2`
    color: #888;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    font-family: Montserrat,sans-serif;
`;

const FormGroup = styled.div`
    display: block;
    color: #666;
    width: 300px;
    margin-bottom: 15px;
    border: none;
    :focus-within label{
    color: #FE4880;
}
`;

const FormLabel = styled.label`
 display: block;
    color: #666;
    font-size: 12px;
    margin-bottom: 5px;
    transition: 0.4s;
    font-family: Montserrat,sans-serif;
`;

const FormInput = styled.input`
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #F8F8F8;
    border-radius: 8px;
    transition: 0.4s;
    border: none;
    font-family: Montserrat,sans-serif;
    :hover{
background-position: 100% 0%;
}

:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  
}
`;

const SubInput = styled.input`
     display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #FFCE00 50%, #FFCE00 50%, #FE4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #FFF;
    font-weight: 700;
    cursor: pointer;
    font-family: Montserrat,sans-serif;
    border: none;
    :hover{
background-position: 100% 0%;
}

:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}
`;

const ErrorDiv = styled.div`
font-family: Montserrat,sans-serif;
`;

function LoginForm({ Login, error }) {
const [details, setDetails] = useState({name: "", email: "", password: ""});

const submitHandler = e =>{
    e.preventDefault();

    Login(details);
}

  return (
    <LogForm onSubmit={submitHandler}>
        <FormInner>
<Formh2>Login</Formh2>
{(error !== "") ? ( <ErrorDiv>{error}</ErrorDiv>) : ""}

<FormGroup>
    <FormLabel htmlFor="name">Name:</FormLabel>
    <FormInput type="text" name="name" id="name" onChange={e => setDetails({...details, name: e.target.value})} value={details.name}/>
</FormGroup>
<FormGroup>
    <FormLabel htmlFor="email">Email:</FormLabel>
    <FormInput type="email" name="email" id="email" onChange={e => setDetails({...details, email: e.target.value})} value={details.email}/>
</FormGroup>
<FormGroup>
    <FormLabel htmlFor="password">Password:</FormLabel>
    <FormInput type="password" name="password" id="password" onChange={e => setDetails({...details, password: e.target.value})} value={details.password}/>
</FormGroup>
<SubInput type="submit" value="LOGIN" />
             </FormInner>
    </LogForm>
  )
}

export default LoginForm